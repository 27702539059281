div {
  font-family: "SimHei";
  flex-shrink: 0;
  box-sizing: border-box !important;
}
.front-layout-page {
  width: 100%;
  // background-color: red;
  .front-layout-page-nav {
    width: 100%;
    height: 58px;
    background-color: #ae3936;
    padding: 0 10%;
    box-sizing: border-box;
    background-image: url("./../../assets/images/nav-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .front-layout-page-nav-title {
      font-size: 28px;
      color: #f7f2a0;
      font-weight: 700;
      word-break: keep-all;
      font-family: "SimHei";
    }
    .front-layout-page-nav-menu {
      flex: 1;
      height: 100%;
      justify-content: flex-start;
      align-items: center;
      padding-left: 5%;
      .front-layout-page-nav-menu-item {
        margin: 0 5vw;
        font-size: 18px;
        color: #fff;
        font-weight: 700;
        word-break: keep-all;
        font-family: "SimHei";
        cursor: pointer;
      }
      .front-layout-page-nav-menu-item.active {
        color: #fceaa4;
      }
      .front-layout-page-nav-menu-item:hover {
        text-decoration-line: underline;
      }
    }
  }
}

.main-layout-content {
  width: 100%;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.front-page {
  width: 1280px;
  max-width: 1280px;
  min-width: 1080px;
  margin: 0 auto;

  .title-row {
    width: 100%;
    height: 75px;
    padding: 0;
    padding-top: 25px;
    border-bottom: 3px solid #e02020;
    justify-content: space-between;
    align-items: center;
    // margin-top: 180px;
    box-sizing: border-box;
    .title-row-text {
      font-size: 20px;
      color: #000;
      font-weight: 700;
      .title-row-main-text {
        color: #e02020;
      }
    }
    .title-row-more-btn {
      background-color: #e02020;
      color: #fff;
      font-size: 15px;
      height: 28px;
      border-radius: 14px;
      width: 72px;
      text-align: center;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding-bottom: 1px;
    }
  }
}

.video-list,
.article-list {
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 24px;
  box-sizing: border-box;
}

.video-item {
  width: calc((1280px - 48px) / 3);
  height: 330px;
  background-color: #fff;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  border-radius: 18px;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
  align-content: flex-start;
  transition: all ease 0.25s;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 18px;
  box-sizing: border-box;
  .video-item-img-container {
    width: 100%;
    height: 240px;
    overflow: hidden;
    // margin: 18px 0;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .video-item-title {
    font-size: 16px;
    color: #000;
    font-weight: 700;
    margin-top: 12px;
  }
  .video-item-date {
    margin-top: 10px;
    font-size: 14px;
    color: #000;
  }
}

.video-item:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.article-item {
  width: calc((1280px - 48px) / 3);
  height: 72px;
  background-color: #fff;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
  align-content: flex-start;
  transition: all ease 0.25s;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  border-left: 4px solid #e02020;
  padding-left: 12px;
  .article-item-title {
    font-size: 16px;
    color: #000;
    font-weight: 700;
  }
  .article-item-date {
    margin-top: 10px;
    font-size: 14px;
    color: #000;
  }
}

.org-tree-node {
  border-radius: 8px;
  .org-tree-node-label {
    background-color: #e02020;
    border-radius: 8px;
    .org-tree-node-label-inner {
      color: #fff;
    }
  }
}

.party-member-group {
  position: relative;
  width: 100%;
  height: 380px;
  background-color: #ae3936;
  border-radius: 12px;
  padding: 24px;
  justify-content: flex-start;
  margin-bottom: 36px;
  .left-btn {
    position: absolute;
    top: 120px;
    left: 12px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: 9990;
    background-color: rgba(57, 50, 50, 0.72);
    justify-content: center;
    align-items: center;
  }
  .right-btn {
    position: absolute;
    top: 120px;
    right: 12px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: 9990;
    background-color: rgba(57, 50, 50, 0.72);
    justify-content: center;
    align-items: center;
  }
  .party-member-group-list {
    position: relative;
    width: 100%;
    height: 240px;
    // background-color: #fff;
    overflow-x: auto;
    scrollbar-width: none; /* 针对 Firefox */
    -ms-overflow-style: none; /* 针对 IE 和 Edge */
    padding-left: 6px;

    .member-card {
      position: relative;
      width: 178px;
      height: 240px;
      border: 1px solid rgba(0, 0, 0, 0);
      transition: all ease 0.25s;
      margin-right: 24px;
      cursor: pointer;
      .member-card-img {
        width: 100%;
        height: 100%;
      }
      .member-card-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 28px;
        background-color: rgba(57, 50, 50, 0.72);
        z-index: 999;
        justify-content: center;
        align-items: center;
        .member-card-name {
          font-size: 16px;
          color: #fff;
        }
      }
    }
    .member-card.active {
      border: 1px solid #fdf19d !important;
    }
  }
  .party-member-group-list::-webkit-scrollbar {
    display: none; /* 针对 Chrome、Safari 和 Opera */
  }
  .desc-content {
    width: 100%;
    height: 72px;
    background-color: #dcabac;
    margin-top: 24px;
    border-radius: 6px;
    text-align: left;
    font-size: 15px;
    line-height: 21px;
    padding: 3px 8px;
    color: #fff;
  }
}

.title-row-1 {
  width: 90%;
  height: 82px;
  margin: 12px auto;
  background-image: url("./../../assets/images/title-bg-1.png");
  background-size: 100% 100%;

  justify-content: center;
  padding-top: 12px;
  .title-row-1-text {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
  }
}

.member-article-item {
  width: 100%;
  height: 200px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 0 18px;
  transition: all ease 0.25s;
  .member-article-item-img {
    img {
      width: auto;
      height: 168px;
    }
  }
  .member-article-item-right {
    flex: 1;
    height: 100%;
    // padding-left: 20px;
    padding: 20px;
    justify-content: space-between;
    align-items: flex-start;
    .member-article-item-title {
      width: 100%;
      text-align: left;
      font-size: 18px;
      // color: #000;
      font-weight: 700;
    }
    .member-article-item-desc {
      font-size: 14px;
      text-align: left;
      margin-top: 18px;
    }
    .member-article-item-date {
      width: 100%;
      text-align: right;
      font-size: 14px;
    }
  }
}

.member-article-item:hover {
  background-color: #ae3936;
  color: #fff !important;
  .member-article-item-title {
    text-decoration-line: underline;
  }
  .member-article-item-desc {
    text-decoration-line: underline;
  }
//   .member-article-item-date {
//     text-decoration-line: underline;
//   }
}


.member-show-bg {
    width: 100%;
    height: calc(100vh - 72px);
    background-image: url('./../../assets/images/member-show-bg.png');
    background-size: 100vw 100vh;
}

.org-tree-container {
    background-color: transparent;
}

.title-row-2 {
    width: 90%;
    height: 120px;
    background-color: blue;
}