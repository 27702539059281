@font-face {
  font-family: "BiaoTi";
  src: url("./../font/zihun181hao-feichibiaotiti-Regular.ttf");
}

@font-face {
  font-family: "SimSun";
  src: url("./../font/202303143698207af1-17c1-40cd-b2c1-a25acf66d76639.otf");
}

@font-face {
  font-family: "SimHei";
  src: url("./../font/202303140025c0a776-8f46-4422-a5e8-8cf49ab6e18964.otf");
}


div,
p {
  box-sizing: border-box;
  margin: 0;
  // font-family: 'OppoHanSansRegular';
  // font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 400;
}

.app-outer-container {
  width: 100%;
  height: 100vh;
  // background-color: red;
  // min-width: 1200px;
  overflow: hidden;
  font-weight: 400;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.flex-row {
  display: -webkit-box;
  /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
  display: -moz-box;
  /* Firefox 17- */
  display: -webkit-flex;
  /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
  display: -moz-flex;
  /* Firefox 18+ */
  display: -ms-flexbox;
  /* IE 10 */
  display: flex;
  /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-col {
  display: -webkit-box;
  /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
  display: -moz-box;
  /* Firefox 17- */
  display: -webkit-flex;
  /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
  display: -moz-flex;
  /* Firefox 18+ */
  display: -ms-flexbox;
  /* IE 10 */
  display: flex;
  /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}



.i-icon{
  display: flex;
}